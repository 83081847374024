import './App.css';
import Nav from './Nav';
import { useState, useEffect }
from 'react'
import {BrowserRouter as Router, Route, Routes, Switch, withRouter}
from 'react-router-dom';
const Adminconsole = () => {

    return (
            <div className="Apps content-box mulish">
                
                <div class="past-project">Past projects</div>
                <div >
                <div class="w-20 margin-sm float-left">
                    
                    <Nav/>
                    
                </div>
                <div class="">
                <div class="image-right">
                <img width="60%" class="rotatexyz img-1 margin-right" src ="/images/k1.png"/>
                 <img width="40%" class="rotatexyz absoluted img-1 margin-right" src ="/images/k2.png"/>
                 </div>
                <div class="about Mulish bold">The <span class="orange">Java Application Admin console</span> helps you monitor your APIs and the overall health of your springboot application. It offers a simple interface to view HTTP requests and responses based on your application logs. Application logs helps to monitor the process calls in an application and offers a good source for trouble shooting and error location. The application can also be used to recover critical information incases where the primary database is lost.</div>
                </div></div>
                 
                <table>
                <tr><th class="Ubuntu">Languages : </th><td>Java, Javascript, MySQL</td></tr>
                <tr><th class="Ubuntu">Frameworks : </th><td class="font-sm"><span class="purple">React Javascript</span>, <span class="purple">Springboot</span> <span class="purple"> Springframework</span> <span class="purple">SpringData</span></td></tr>
                </table>
            </div>

            );
}

export default Adminconsole;




