import './App.css';
import Nav from './Nav';
import { useState, useEffect }
from 'react'
import {BrowserRouter as Router, Route, Routes, Switch, withRouter}
from 'react-router-dom';
const Home = () => {

    return (
            <div className="Apps content-box mulish">
                
                <div class="past-project">Past projects</div>
                <div >
                <div class="w-20 relative margin-sm float-left">
                    
                    <Nav/>
                    
                </div>
                <div class=" ">
                <div class="image-right">
                <img width="60%" class="rotatexyz img-1 margin-right" src ="/images/q1.png"/>
                 <img width="40%" class="rotatexyz absolutes img-1 p1 margin-right" src ="/images/q2.png"/>
            <img width="20%" class="rotatexyz img-1 margin-right" src ="/images/x3.png"/>     
            <img width="30%" class="rotatexyz absolutes img-1 p1 margin-right" src ="/images/q3.png"/>
                 </div><div class="about Mulish bold">The <span class="orange">Dairy IFMS</span> is a multi-tenant ERP applicaton that has been developed to enable farmer societies that buy produce from farmers to digitize and manage their operations from a central web based application. Farmer records are input from an android application which post them to a remote database where they can be accessed from the central office. The application is suitable for coffee, milk, tea and other types of farmer produce that are collected and registered on farmer cards or books</div>
                </div></div>
                 
                <table>
                <tr><th class="Ubuntu">Languages : </th><td>Java, Javascript, MySQL</td></tr>
                <tr><th class="Ubuntu">Frameworks : </th><td class="font-sm"><span class="purple">React Javascript</span>, <span class="purple">Springboot</span> <span class="purple"> Springframework</span> <span class="purple">SpringData</span></td></tr>
                </table>
            </div>

            );
}

export default Home;


