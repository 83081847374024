import logo from './logo.svg';

import Home from './Home';
import Amopslms from './Amopslms';
import Adminconsole from './Adminconsole';
import Peely from './peely';
import { useEffect, useState } from "react";
import {BrowserRouter as Router, Route, Routes, Switch, withRouter}
from 'react-router-dom';
import './App.css';

function App() {


    return (
            <div className="App">
                <div class="float-left w-100o header">
                    <div class="profile-pic float-left"></div>
                    <div class="font-xl w-75 Ubuntu">Hello There, I am Samuel Maina a Fullstack Software Developer</div>
                    <div class=""><button class="orange">5 years</button></div>
                    <div class="itemx"><span class="orange">Java</span> || <span class="orange">JavaScript</span>      ||       <span class="font-lg orange">Frontend and Backend Technologies</span>
            
                        <span class="orange">Java Script</span>||
                        <span class="orange">React Js</span>||
                        <span class="orange">Html & css</span>||
                        <span class="orange">Java</span>||
                        <span class="orange">Java EE</span>||
                        <span class="orange">Node Js</span>||
                        <span class="orange">hibernate & JPA</span>||
                        <span class="orange">Spring Data</span>||
                        <button class="orange">Micro-services with Spring cloud</button>||
                        <span class="orange">Spring boot & the spring framework</span></div>||
                    <div class="contacts Mulish c"> +254707588686  || maina9025@gmail.com || contact@devsam.peelytheblog.com </div>
                         
                </div>  
                <Router>
                    <Switch>
                    <Route exact path="/home" component={Home}/>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/amopslms" component={Amopslms}/>
                    <Route exact path="/adminconsole" component={Adminconsole}/>
                    <Route exact path="/peely" component={Peely}/>
            
                    </Switch>
                </Router>
            </div>
            );
}

export default App;
