import './App.css';
import Nav from './Nav';
import { useState, useEffect }
from 'react'
import {BrowserRouter as Router, Route, Routes, Switch, withRouter}
from 'react-router-dom';
const Amopslms = () => {

    return (
            <div className="Apps content-box mulish">
                
                <div class="past-project">Past projects</div>
                <div >
                <div class="w-20 margin-sm float-left">
                    
                    <Nav/>
                    
                </div>
                <div class="relatived ">
                <div class="image-right">
                <img width="70%" class="rotatexyz img-1 margin-right" src ="/images/a2.png"/>
                 <img width="50%" class="rotatexyz absolutde img-1 p1 margin-right" src ="/images/a1.png"/>
                 <img width="40%" class="rotatexyz absolutes img-1 margin-right" src ="/images/a3.png"/>
                 <img width="10%" class="rotatexyz absoluted img-1 margin-right" src ="/images/a7.png"/>
                 <img width="30%" class="rotatexyz absolutes img-1 margin-right" src ="/images/a5.png"/>
                 <img width="20%" class="rotatexyz absoluted img-1 p1 margin-right" src ="/images/a6.png"/>
                 
                 </div>
                 <div class="about Mulish bold">The <span class="orange">AmazingDevOps-LMS</span>  is a learning management application that was comprises of five parts:
                 <u class="Ubuntu">  A student frontend</u> <u>A site administrator frontend</u>
                 <u> A Tutor frontend</u>
                 <u> The LMS website.</u>
                 The application is fairly complex and constitutes a large number of functional requirements like registration for new course, payment, defferment,and important site metrics like enrolment and member interactions
                 </div>
                 <table>
                <tr><th class="Ubuntu">Languages : </th><td>Java, Javascript, MySQL</td></tr>
                <tr><th class="Ubuntu">Frameworks : </th><td class="font-sm"><span class="purple">React Javascript</span>, <span class="purple">Springboot</span> <span class="purple"> Springframework</span> <span class="purple">SpringData</span></td></tr>
                </table>
                </div></div>
                 
                
            </div>

            );
}

export default Amopslms;





