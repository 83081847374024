import { Link, NavLink } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
class Nav extends React.Component {
    render() {

        return (
                <div className="top-nav  font-sdm text-bold text-gray flex-verticdal fledx-center">
                    <Link to="/">
                    
                    </Link>
                
                    <div class="">
                        <NavLink to="/home" activeClassName="selected-2" className='nav-item  font-md Mulish font-sm' >
                            <div>
                                Dairy-IFMS 
                
                            </div>
                        </NavLink>
                        
                        <NavLink to="/amopslms" activeClassName="selected-2" className='nav-item  font-md Mulish font-sm' >
                            <div>
                                AmazingDevOps LMS
                
                            </div>
                        </NavLink>
                        <NavLink to="/peely" activeClassName="selected-2" className='nav-item  font-md Mulish font-sm' >
                            <div>
                                PeelyTheBlog 
                
                            </div>
                        </NavLink>
                        
                        <NavLink to="/adminconsole" activeClassName="selected-2" className='nav-item  font-md Mulish font-sm' >
                            <div>
                               Logging console for java application
                
                            </div>
                        </NavLink>
                        
                        
                        
                    </div></div>
                );
    }
}
export default Nav;


